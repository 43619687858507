<template>
    <CompModal ref="modal" title="加入黑名单" height="auto" :onSuccess="onSuccess" @on-close="onClearForm">
        <div class="item-box">
            <div class="name">1. 加入黑名单原因:</div>
            <CheckboxGroup v-model="causes" style="width: 400px">
                <Checkbox v-for="(item, idx) in causesList" :key="idx" :label="item.dictKey">{{ item.dictValue }}</Checkbox>
            </CheckboxGroup>
        </div>
        <div class="item-box row">
            <div class="name">2. 限制时长:</div>
            <Input v-model="duration" type="number">
                <template #append>
                    <span>天</span>
                </template>
            </Input>
        </div>
    </CompModal>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Request from "../../utils/request"

export default {
    components: {
        CompModal,
    },

    data() {
        return {
            causesList: null,
            causes: [],
            duration: "15",
        }
    },

    methods: {
        async display(supplierId, supplierName) {
            if (!this.causesList) {
                await this.getCausesList()
            }

            this.supplierId = supplierId
            this.supplierName = supplierName

            this.$refs.modal.display()
        },

        onClearForm() {
            this.causes = []
            this.duration = "15"
        },

        /**
         * 获取
         */
        async getCausesList() {
            return Request.dict("BLACK_LIST").then(res => {
                this.causesList = res
            })
        },

        onSuccess() {
            if (this.causes.length === 0) {
                return this.$Message.warning("请选择加入黑名单原因")
            }

            if (!this.duration.trim()) {
                return this.$Message.warning("请输入限制时长")
            }

            Request.post(
                "/gateway/api/sycompany/pc/blacklist/addBlacklist",
                {
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    supplierId: this.supplierId,
                    supplierName: this.supplierName,
                    limitTimeDay: Number(this.duration),
                    remark: this.causesList
                        .filter(item => this.causes.includes(item.dictKey))
                        .map(item => item.dictValue)
                        .join(","),
                    staffId: parent.vue.loginInfo.userinfo.custGlobalId,
                    staffName: parent.vue.loginInfo.userinfo.nickName,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("操作成功")
                this.$refs.modal.close()
            })
        },
    },
}
</script>

<style lang="less" scoped>
.item-box {
    margin: 20px;

    .name {
        margin-bottom: 5px;
        flex-shrink: 0;
        color: #666;
    }

    &.row {
        display: flex;
        align-items: center;

        .name {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }

    .ivu-checkbox-wrapper {
        width: 50%;
        padding-right: 10px;
        margin-right: 0 !important;
        margin-bottom: 5px !important;
    }
}
</style>
